import { type CookieOptions, useCookie, type CookieRef } from "#app";
import type { IFilterOption } from "~/composables/filters/useFilters";

export const useTeamsCookies = () => {
  const pageQueryOpts = useState<string | null>("page_query_opts", () => null);

  function getQueryOpts(cookie: string | null = null): Record<string, any> | null {
    const page_opts: CookieRef<Record<string, any>> = useCookie(String(cookie));

    if (page_opts.value) {
      return page_opts.value;
    }
    return null;
  }

  function getOpt(key: string, cookie: string | null = null): string | string[] | null {
    const page_opts: CookieRef<Record<string, any>> = useCookie(String(cookie));
    if (page_opts.value) return page_opts.value[key];
    return null;
  }

  function setQueryOpts(key: string, value: number | string | undefined | null, cookie: string | null = null) {
    const cookies_opts = {
      expires: new Date(new Date().getDate() + 7),
      maxAge: 60 * 60 * 24 * 7,
    };

    const page_opts: CookieRef<Record<string, any>> = useCookie(String(cookie), cookies_opts);

    if (!page_opts.value) page_opts.value = {};

    const queryObj = page_opts.value;

    queryObj[key] = value;
    page_opts.value = queryObj;
  }

  function setFiltersQueryOpts(filter: IFilterOption, cookie: string | null = null) {
    const cookies_opts: CookieOptions<any> & {
      readonly?: false | undefined;
    } = {
      expires: new Date(new Date().getDate() + 1),
      maxAge: 60 * 60 * 24,
      readonly: false, // Add the readonly property with a value of false
    };

    const page_opts: CookieRef<Record<string, any>> = useCookie(String(cookie), cookies_opts);

    if (!page_opts.value) page_opts.value = {};

    const queryObj = page_opts.value;

    if (["trainings_admin", "period", "session_tag", "training_tags", "habilitation_name"].includes(filter.filter_id)) {
      queryObj[filter.filter_id] = filter.selected_values
        .map((x) => encodeURIComponent(x ? x.id.toString() : "null"))
        .join(",");
      page_opts.value = queryObj;
    } else {
      queryObj[filter.filter_id] = filter.selected_values
        .map((x) => encodeURIComponent(x ? x.toString() : "null"))
        .join(",");
      page_opts.value = queryObj;
    }
  }

  function clearQueryOpt(key: string, cookie: string | null = null) {
    const page_opts: CookieRef<Record<string, any>> = useCookie(String(cookie));
    const queryObj = page_opts.value;

    delete queryObj[key];
    page_opts.value = queryObj;
  }

  return { getQueryOpts, getOpt, setQueryOpts, clearQueryOpt, setFiltersQueryOpts, pageQueryOpts };
};
