import { defineStore } from "pinia";

import type { ErrorObject } from "@vuelidate/core";
import { navigateTo } from "#app";
import { format } from "date-fns";
import type { IOffCatalogRequestStore } from "~~/utils/interfaces/requests-interfaces";
import { OffCatalogStep } from "~/utils/enums/catalog.enums";
import { useOffCatalogValidator } from "~/composables/catalog/useOffCatalogValidator";
import { SessionItemModalityEnum } from "~/utils/enums/session-items.enums";
import { RegistrationType } from "~/utils/enums/training.enums";
import { useRequestsApi } from "~/composables/requests/useRequestsApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { useCreateRequestStore } from "~~/store/requests/create-request.store";
import { useAuthStore } from "~~/store/auth/auth.store";

export const useOffCatalogRequests = defineStore("offCatalogRequests", {
  state: (): IOffCatalogRequestStore => {
    return {
      training: {
        training_partner: null,
        training: null,
        external_url: null,
        duration_hours: null,
        format: null,
        price: null,
        inclTax: false,
        registration_type: RegistrationType.SELF_REGISTRATION,
        modality: SessionItemModalityEnum.SPECIFIC_DATES,
        start_date: null,
        end_date: null,
        employee_comment: null,
      },
      errors: {},
      showModal: false,
      step: OffCatalogStep.AskTrainingDesired,
      isDesktop: true,
    };
  },
  getters: {
    hasError(): boolean {
      return !!Object.keys(this.errors)?.length;
    },
  },
  actions: {
    pushValidationErrors(errors: ErrorObject[]) {
      errors.forEach((err: ErrorObject) => {
        this.errors[err.$propertyPath] = err.$message as string;
      });
    },

    async validator() {
      const offCatalogValidator = useOffCatalogValidator();
      this.errors = {};

      if (this.step === OffCatalogStep.WhichTraining) await offCatalogValidator.validateStepTraining();
      if (this.step === OffCatalogStep.UserSpecifyContext) await offCatalogValidator.validateStepEmployeeComment();
    },

    async createOffCatalogRequest() {
      const requestsApi = useRequestsApi();
      const createRequestStore = useCreateRequestStore();
      const authStore = useAuthStore();
      const feedback = useFeedback();
      if (authStore.user?.user_id) {
        const { data } = await requestsApi.createOffCatalogRequest({
          training_partner_id: this.training.training_partner?.training_partner_id ?? null,
          training_partner_name: this.training.training_partner?.name ?? null,
          training_id: this.training.training?.training_id ?? null,
          training_title: this.training.training?.title ?? null,
          modality: this.training.modality,
          format: this.training.format,
          registration_type: this.training.registration_type,
          start_date: this.training?.start_date ? format(this.training.start_date, "yyyy-MM-dd") : null,
          end_date: this.training?.end_date ? format(this.training.end_date, "yyyy-MM-dd") : null,
          price: this.training.price,
          duration_hours: this.training.duration_hours,
          incl_tax: this.training.inclTax,
          user_ids: createRequestStore.request.users_to_invite.length
            ? createRequestStore.request.users_to_invite.map((u) => u.user_id)
            : [authStore.user?.user_id],
          manager_id: createRequestStore.isCheckedForOther ? authStore.user?.user_id : null,
          external_url: this.training.external_url,
          manager_comment: createRequestStore.request?.manager_comment ?? null,
          manager_priority: createRequestStore.request?.manager_priority?.key ?? null,
          employee_comment: this.training.employee_comment,
        });

        if (data.value?.requests?.length) {
          feedback.success("Votre demande a bien été enregistrée", "small");
          this.showModal = false;
          if (!createRequestStore.isCheckedForOther) {
            return navigateTo(`my-trainings/${data.value.requests[0]}`);
          } else {
            return navigateTo(`requests`);
          }
        }
      }
    },
  },
});
