<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">

const emit = defineEmits(["hide"])

function hideModal() {
  emit("hide")
}

function keyEscPress(e: KeyboardEvent) {
  if (e.key === "Escape") {
    hideModal()
  }
}

onMounted(() => {
  document.addEventListener("keyup", keyEscPress)
})

onUnmounted(() => {
  document.removeEventListener("keyup", keyEscPress)
})


</script>

<template>

  <div class="center_modal_main" @click.self="hideModal">
    <div class="center_card" v-bind="$attrs">
        <slot name="body"></slot>
    </div>
  </div>

</template>


<style scoped lang="scss">

@use "assets/scss/texts";
@use "assets/scss/modal";


.center_card {
    background-color: var(--white-main);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 8px;
}


@media (max-width: 768px) {
  .center_card {
    &[data-responsive="true"] {
      max-height: 100% !important;
      max-width: 100% !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translate(0, 0);
      border-radius: 0;
    }
  }
}

.center_modal_main {
  @apply background_modal__grey;
  z-index: 501;
}
</style>