import { defineStore } from "pinia";
import { SessionsApi } from "@simbelapp/order-sdk";
import { useProjectsStore } from "../project/projects.store";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { useSessionsApi } from "~/composables/sessions/useSessionsApi";
import type { ISessionsStore } from "~/utils/interfaces/sessions-interfaces";
import { useTrainingsPlansApi } from "~~/composables/trainings-plans/useTrainingsPlansApi";
import type { ISessionDraft } from "~~/utils/interfaces/project-interfaces";
import type { ITrainingSession } from "~~/utils/interfaces/trainings-interface";
import { MicroserviceEnum, type ItemType } from "~/utils/enums/common.enums";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useFormats } from "~/composables/utils/useFormat";

const formats = useFormats();

export const useSessionsStore = defineStore("sessionsStore", {
  state: (): ISessionsStore => {
    return {
      training_plan_session_individual: null,
      training_plan_kpi: null,
      session_type: null,
      session_item_sessions: null,
      show_session_modal: false,
      show_csv_import_modal: false,
      show_csv_export_modal: false,
      session_item_sessions_draft: null,
      show_update_session_modal: false,
      show_session_status_action_modal: {
        registered: false,
        completed: false,
        canceled: false,
        not_present: false,
        proposal_refused: false,
        end_training: false,
        no_show: false,
      },
      showHideKpi: false,
      showHideBlock: false,
      show_modal_remove_project: false,
      add_project_on_session_page: false,
      errorConfirmProject: false,
    };
  },
  getters: {},
  actions: {
    async fetchTrainingPlanKpi(currGrid: string, type: string): Promise<void> {
      const feedback = useFeedback();
      const trainingPlan = useTrainingsPlansApi();
      const data = await trainingPlan.fetchTrainingPlanKpi(currGrid as ItemType, type);

      if (data) {
        this.training_plan_kpi = data;
      } else {
        feedback.error(`erreur`, "small");
      }
    },
    async fetchTrainingPlanSession(id: string): Promise<boolean> {
      const feedback = useFeedback();
      const trainingPlan = useTrainingsPlansApi();

      // Empty project since it's the same modal
      const projectStore = useProjectsStore();
      projectStore.training_plan_project_individual = null;

      this.training_plan_session_individual = null;
      const { data } = await trainingPlan.fetchOneTrainingPlan(id);

      if (data.value) {
        this.training_plan_session_individual = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }

      return !!data.value;
    },
    async fetchTrainingPlanIndividualByRequestId(request_id: string) {
      const trainingPlan = useTrainingsPlansApi();
      const projectStore = useProjectsStore();
      projectStore.training_plan_project_individual = null;
      this.training_plan_session_individual = null;
      const { data } = await trainingPlan.fetchTrainingPlanIndividualByRequestId(request_id);

      if (data.value) {
        this.training_plan_session_individual = data.value;
      }

      return !!data.value;
    },
    async fetchSessionItemSessions(session_item_id: string, training_id: string = ""): Promise<boolean> {
      const feedback = useFeedback();
      const sessions = useSessionsApi();

      this.session_item_sessions = null;
      const { data } = await sessions.fetchSessionItemSessions(session_item_id, training_id);

      if (data.value) {
        this.session_item_sessions = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }

      return !!data.value;
    },
    async fetchSessionItemSessionsDraft(training_id: string): Promise<boolean> {
      const feedback = useFeedback();
      const sessions = useSessionsApi();

      this.session_item_sessions_draft = null;
      const { data } = await sessions.fetchSessionItemSessionsDraft(training_id);

      if (data.value) {
        this.session_item_sessions_draft = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }

      return !!data.value;
    },

    async createSession(newSession: ITrainingSession) {
      const feedback = useFeedback();

      try {
        if (newSession) {
          const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, SessionsApi);
          newSession.sessionTimeslot?.forEach((timeslot) => {
            timeslot.sessionId = newSession.sessionItemId;
            timeslot.startDate = formats.toDateISOString(new Date(timeslot.startDate));
            timeslot.endDate = formats.toDateISOString(new Date(timeslot.endDate));
          });
          await apiInstance.createSession({
            createSessionDto: {
              sessionItemId: newSession.sessionItemId ?? "",
              startDate: formats.toDateISOString(new Date()),
              endDate: formats.toDateISOString(new Date()),
              address: newSession.trainingAddress ?? "",
              designation: newSession.designation ?? "",
              maxNbAttendees: newSession.maxNbAttendees ?? 1,
              minNbAttendees: newSession.minNbAttendees ?? 1,
              overbookingNotAllowed: newSession.overbookingNotAllowed,
              batchCreateTimeslots: {
                createTimeslotsDto: newSession.sessionTimeslot ?? [],
              },
            },
          });
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async updateSessionDraft(session_draft: ISessionDraft) {
      const sessions = useSessionsApi();
      const feedback = useFeedback();

      try {
        if (session_draft.session_draft_id) {
          await sessions.updateSessionDraft(session_draft.session_draft_id, {
            start_date: session_draft.start_date,
            end_date: session_draft.end_date,
            location: session_draft.location,
            designation: session_draft.designation,
          });
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    // Canceled attendees are not shown in the training plan
    // That's why we only update the state on the front
    cancelTrainingPlanIndividualSession() {
      if (this.training_plan_session_individual) {
        this.training_plan_session_individual.status = "canceled";
        this.training_plan_session_individual.allowedActions = [];
      }
    },

    // Refused proposals are not shown in the training plan
    // That's why we only update the state on the front
    refuseTrainingPlanIndividualSession() {
      if (this.training_plan_session_individual) {
        this.training_plan_session_individual.status = "canceled";
        this.training_plan_session_individual.allowedActions = [];
      }
    },

    async cancel(sessionId: string) {
      const sessions = useSessionsApi();
      const data = await sessions.cancelSession(sessionId);
      return data;
    },

    async fetchSessionsByTrainingId(training_id: string, withProject?: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, SessionsApi);

      const response = await apiInstance.sessionsControllerFindAllTrainingAvailableSessions({
        trainingId: training_id,
        withProjects: (withProject as string) || "true",
      });
      const feedback = useFeedback();

      if (response) {
        return response;
      } else {
        feedback.error(`erreur`, "small");
      }
    },
  },
});
