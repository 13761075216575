import * as amplitude from "@amplitude/analytics-browser";
import { useAuthStore } from "~/store/auth/auth.store";

export default defineNuxtPlugin((nuxtApp) => {
  const environment = nuxtApp.$config.public.ENV;
  const authStore = useAuthStore();

  if (["DEV", "PROD"].includes(environment)) {
    authStore.initAmplitude();
    return {
      provide: {
        Amplitude: amplitude,
      },
    };
  } else {
    return {};
  }
});
