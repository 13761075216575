export const useFormats = () => {
  function toEuroCurrency(number: number) {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(number ?? 0);
  }

  function formatTo2Decimals(num: number): number {
    return num % 1 === 0 ? num : Number(num.toFixed(2));
  }

  function toLocalNumber(number: number) {
    return new Intl.NumberFormat("fr-FR").format(number);
  }

  function toStringDisplay(...val: string[]) {
    val = val.filter(Boolean);
    return val.length ? val.join(" ") : "";
  }

  function toPercentageDisplay(nb: number) {
    if (isNaN(nb) || !isFinite(nb)) return `${parseFloat(Number(0).toFixed(2))} %`;
    return `${parseFloat(Number(nb).toFixed(2))} %`;
  }

  function toDateDisplay(date: string | Date): string {
    if (date instanceof Date) {
      return new Intl.DateTimeFormat("fr-FR").format(date);
    } else {
      return new Intl.DateTimeFormat("fr-FR").format(new Date(date));
    }
  }

  function toDateWithTime(date: string | Date): string {
    const newDate = new Date(date);
    const startOfDay = new Date(date).setHours(0, 0, 0, 0);
    const startDate = new Date(startOfDay);

    if (newDate > startDate) {
      return dateFormat(newDate, true);
    } else {
      return dateFormat(newDate);
    }
  }

  function dateFormat(date: Date, hour = false) {
    if (hour) {
      return date.toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return date
        .toLocaleDateString("fr-FR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "/");
    }
  }

  function toDateLongDisplay(date: string | Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    if (date instanceof Date) {
      return date.toLocaleDateString("fr-FR", options);
    } else {
      return new Date(date).toLocaleDateString("fr-FR", options);
    }
  }

  function toDateISOString(date: Date | string): string {
    if (date instanceof Date) {
      return date.toISOString().split("T")[0];
    } else {
      return date.split("T")[0];
    }
  }

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  }

  return {
    formatTo2Decimals,
    toLocalNumber,
    toEuroCurrency,
    toStringDisplay,
    toDateDisplay,
    convertMsToTime,
    toPercentageDisplay,
    toDateLongDisplay,
    toDateWithTime,
    dateFormat,
    toDateISOString,
  };
};
