import { startOfDay } from "date-fns";
import { defineStore } from "pinia";
import { useBookingsStore } from "../bookings/bookings.store";
import { useCatalogStore } from "../catalog/catalog.store";
import { useTrainingPartnersStore } from "../training-partners/training-partners.store";
import { useCreateProjectCoreStore } from "./create-project-core.store";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { useProjectsValidation } from "~/composables/projects/useProjects";
import { useSessionsApi } from "~/composables/sessions/useSessionsApi";
import { useRequestsStore } from "~/store/requests/requests.store";
import { useSessionItemStore } from "~/store/session-item/session-item-store";
import { useSessionsStore } from "~/store/sessions/sessions-store";
import { useTrainingsStore } from "~/store/trainings/trainings.store";
import { ItemType, VatEnum } from "~/utils/enums/common.enums";
import { ProjectNavEnum, ProjectTypeEnum } from "~/utils/enums/project.enums";
import { SessionItemModalityEnum, SessionItemTypeEnum } from "~/utils/enums/session-items.enums";
import type { ICreateProject, IProjectApiPayload } from "~/utils/interfaces/project-interfaces";
import { usePagination } from "~~/composables/paginations/usePagination";
import { useTrainingsPlansApi } from "~~/composables/trainings-plans/useTrainingsPlansApi";
import { useSessionDetailsStore } from "~~/store/sessions/session-details-store";
import type { IFinancingComponent } from "~~/utils/interfaces/common-interfaces";
import { TrainingTypeEnum } from "~/utils/enums/training.enums";
import { BookingStatusEnum } from "~/utils/enums/bookings-events.enums";

export const useCreateProjectStore = defineStore("createProjectStore", {
  state: (): ICreateProject => {
    return {
      project: {
        project_id: null,
        training: {
          training_id: null,
          project_title: null,
        },
        session_item: {
          session_item_id: null,
          session_item_type: null,
        },
        session: {
          session_id: null,
          session_draft_id: null,
          session_draft: {
            session_draft_id: null,
            start_date: null,
            end_date: null,
            format: null,
            duration: null,
            location: null,
            designation: null,
            capacity: null,
          },
          create_session_step: false,
        },
        users: {
          users_id: null,
          inscribed: null,
        },
        financing: {
          price_excl_tax: null,
          budget_excl_tax: null,
          opco_excl_tax: null,
          cpf_excl_tax: null,
          capacity: null,
          users_buckets: [],
        },
        reportingPeriod: null,
        status: null,
        comment: null,
        notification: {
          notification_type: BookingStatusEnum.BOOKING_CONFIRMED,
          do_notify_main_contact: false,
          main_contact_email: null,
        },
        source_type: null,
        source_id: null,
        project_type: null,
      },
      nav: ProjectNavEnum.TRAINING,
      show_exit: false,
      show_modal: false,
      previous_modal_state: {
        modal_funnel: false,
        modal_session_details_complete: false,
        modal_show_edit: false,
        modal_show_multiple: false,
      },
      show_confirm: false,
      show_confirm_send_notification: false,
      show_end_modal: false,
      show_edit: false,
      show_session_details_complete: false,
      edit_mode: false,
      fetch_complete_done: false /** loading state for better UX **/,
      redirect_session_id: null,
    };
  },
  getters: {
    isTrainingChosen(): boolean {
      return !!this.project.training.training_id;
    },
    isSessionItemChosen(): boolean {
      return !!(this.isTrainingChosen && this.project.session_item.session_item_id);
    },
    isTrainingStatusDraft(): boolean {
      return !!(this.project.training.training_id?.status === "draft");
    },
    getSessionItemType(): SessionItemTypeEnum {
      return this.project.session_item.session_item_type as SessionItemTypeEnum;
    },
    isSessionChosen(): boolean {
      return !!(this.isTrainingChosen && this.isSessionItemChosen && this.project.session.session_id);
    },
    isSessionDraftChosen(): boolean {
      return !!this.project.session.session_draft_id;
    },
    isCpfEligible(): boolean {
      return !this.project.training.training_id || this.project.training.training_id?.is_cpf_eligible;
    },
    isOpcoEligible(): boolean {
      return !this.project.training.training_id || this.project.training.training_id?.is_opco_eligible;
    },
    hasPriceError(): boolean {
      return (
        Number(this.project.financing.price_excl_tax ?? 0) !==
        Number(this.project.financing.opco_excl_tax ?? 0) +
          Number(this.project.financing.cpf_excl_tax ?? 0) +
          Number(this.project.financing.budget_excl_tax ?? 0)
      );
    },
    isGroupAvailable(): boolean {
      return this.isTrainingChosen && !!this.project.training.training_id?.group_session_item;
    },
    isIndividualAvailable(): boolean {
      return this.isTrainingChosen && !!this.project.training.training_id?.individual_session_item;
    },
    isTrainingOnDemand(): boolean {
      return (
        this.isTrainingChosen &&
        this.project.session_item.session_item_id?.modality === SessionItemModalityEnum.ON_DEMAND
      );
    },
    getFinancingInfo(): IFinancingComponent {
      const budget = Number.isFinite(this.project.financing.budget_excl_tax)
        ? this.project.financing.budget_excl_tax
        : 0;

      const opco = Number.isFinite(this.project.financing.opco_excl_tax) ? this.project.financing.opco_excl_tax : 0;

      const cpf = Number.isFinite(this.project.financing.cpf_excl_tax) ? this.project.financing.cpf_excl_tax : 0;

      const financingInfos: IFinancingComponent = {
        isCPFEligible: this.isCpfEligible,
        isOpcoEligible: this.isOpcoEligible,
        isWithSession: this.isSessionChosen,
        isWithTraining: this.isTrainingChosen,
        priceExclTax: this.project.financing.price_excl_tax,
        totalAttendees: this.project.users?.users_id?.length ?? null,
        opcoAmountExclTax: opco,
        budgetAmountExclTax: budget,
        cpfAmountExclTax: cpf,
        groupPriceExclTax: this.project.training.training_id?.group_price_excl_tax ?? null,
        individualPriceExclTax: this.project.training.training_id?.individual_price_excl_tax ?? null,
        maxNbAttendees:
          this.project.session.session_id?.max_nb_attendees ??
          this.project.training.training_id?.group_session_item?.max_nb_attendees ??
          null,
        isIndividualSession:
          (this.project.training.training_id?.individual_session_item &&
            this.getSessionItemType === SessionItemTypeEnum.INDIVIDUAL) ??
          false,
        vatType: Number.isFinite(this.project.training.training_id?.individual_session_item?.vat)
          ? VatEnum.TTC
          : VatEnum.HT,
        usersBuckets: this.project.financing.users_buckets,
        trainingIsDraft: this.isTrainingStatusDraft ?? false,
        trainingIsVisible: this.project.training.training_id?.visible ?? true,
      };

      return financingInfos;
    },
    getProjectApiPayload(): IProjectApiPayload {
      const payload = {
        project_id: this.project.project_id,
        training_id: this.project.training.training_id?.training_id ?? null,
        session_item_type: this.project.session_item.session_item_type ?? null,
        session_item_id: this.project.session_item.session_item_id?.session_item_id ?? null,
        ...((this.project.session_item.session_item_type === SessionItemTypeEnum.GROUP ||
          (this.project.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL &&
            !this.project.users.users_id?.length)) && {
          budget_excl_tax: this.project.financing.budget_excl_tax,
          opco_excl_tax: this.project.financing.opco_excl_tax,
          cpf_excl_tax: this.project.financing.cpf_excl_tax,
        }),
        reporting_period_id: this.project.reportingPeriod?.reporting_id ?? null,
      };

      if (this.project.users.users_id?.length) {
        if (this.project.financing.users_buckets?.length) {
          Object.assign(payload, {
            project_users: this.project.financing.users_buckets?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
                ...(this.project.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL && {
                  budget: Number.isFinite(u.budget) ? u.budget : 0,
                  opco: Number.isFinite(u.opco) ? u.opco : 0,
                  cpf: Number.isFinite(u.cpf) ? u.cpf : 0,
                }),
              };
            }),
          });
        } else {
          Object.assign(payload, {
            project_users: this.project.users.users_id?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
              };
            }),
          });
        }
      }

      return payload;
    },
  },
  actions: {
    setProjectFinancing(financingDatas: IFinancingComponent): void {
      this.project.financing.budget_excl_tax = financingDatas.budgetAmountExclTax;
      this.project.financing.opco_excl_tax = financingDatas.opcoAmountExclTax;
      this.project.financing.cpf_excl_tax = financingDatas.cpfAmountExclTax;
      this.project.financing.capacity = financingDatas.maxNbAttendees;
      this.project.financing.price_excl_tax = financingDatas.priceExclTax;
      this.project.financing.users_buckets = financingDatas.usersBuckets;
    },
    async performImpactsTrainingChange() {
      const sessionStore = useSessionsStore();

      sessionStore.session_item_sessions = null;
      sessionStore.session_item_sessions_draft = null;

      this.project.session_item.session_item_type = null;
      this.project.session_item.session_item_id = null;
      this.project.session.session_id = null;
      this.project.session.session_draft_id = null;
      this.project.financing.price_excl_tax = null;
      this.project.financing.budget_excl_tax = null;
      this.project.financing.cpf_excl_tax = null;
      this.project.financing.opco_excl_tax = null;

      const types = this.project.training.training_id?.session_items_types;
      const isIndividualSession = types?.some((type) => type === SessionItemTypeEnum.INDIVIDUAL);
      const isGroupSession = types?.some((type) => type === SessionItemTypeEnum.GROUP);

      if (!isGroupSession && isIndividualSession && this.project.training.training_id?.individual_session_item) {
        this.project.session_item.session_item_id = this.project.training.training_id?.individual_session_item;
        this.project.session_item.session_item_type = SessionItemTypeEnum.INDIVIDUAL;
      }

      if (this.project.session_item.session_item_type) {
        this.setSessionItemsSessions(this.project.session_item?.session_item_type);
      }

      if (this.project.training.training_id?.training_partner_id) {
        const trainingPartnersStore = useTrainingPartnersStore();
        const tp = await trainingPartnersStore.findOneTrainingPartner(
          this.project.training.training_id?.training_partner_id,
        );

        this.project.notification.main_contact_email = tp?.main_contact_email;
      }

      if (this.project.training.training_id?.training_partner?.main_contact_email) {
        this.project.notification.main_contact_email =
          this.project.training.training_id?.training_partner?.main_contact_email;
      }
    },
    async performImpactsSessionChange(session: any) {
      const sessionApi = useSessionsApi();
      const trainingPlanApi = useTrainingsPlansApi();

      this.project.users.inscribed = null;

      if (session.session_id) {
        this.project.session.session_id = session;
        this.project.session.session_draft_id = null;
        if (this.project.source_type !== ItemType.REQUEST) {
          const { data } = await trainingPlanApi.fetchOneTrainingPlanDetailsAttendees(
            session.session_id,
            ItemType.SESSION,
          );
          this.project.users.inscribed = data.value?.filter((a: any) => a.session_id !== "fake") ?? null;
        }
      } else if (session.session_draft_id) {
        this.project.session.session_draft_id = session;
        this.project.session.session_id = null;
        if (this.project.source_type !== ItemType.REQUEST) {
          const { data } = await sessionApi.fetchSessionDraftDetailsAttendees(session.session_draft_id);
          this.project.users.inscribed = data.value;
        }
      }
    },
    setSessionItemsSessions(val: SessionItemTypeEnum) {
      const sessionsStore = useSessionsStore();
      if (this.project.training.training_id && val) {
        const session_items: Record<string, any> = {
          group: this.project.training.training_id?.group_session_item,
          individual: this.project.training.training_id?.individual_session_item,
        };

        this.project.session_item.session_item_id = session_items[val];
        this.project.financing.price_excl_tax = session_items[val]?.price_excl_tax;
        this.project.financing.budget_excl_tax = session_items[val]?.price_excl_tax;

        if (this.project.session_item?.session_item_id) {
          if (this.project.session_item.session_item_id.modality !== SessionItemModalityEnum.ON_DEMAND) {
            sessionsStore.fetchSessionItemSessions(
              this.project.session_item?.session_item_id.session_item_id as string,
            );
          } else if (this.project.session_item.session_item_id.modality === SessionItemModalityEnum.ON_DEMAND) {
            this.setOnDemandDates();
          }
        }
      }
    },
    loadUsersFinancing() {
      const requestsStore = useRequestsStore();

      let source_budget: number | null = null;

      if (
        this.project.source_type === ItemType.REQUEST &&
        Number.isFinite(requestsStore.request_details?.price_excl_tax)
      ) {
        source_budget = Number(requestsStore.request_details?.price_excl_tax);
      } else if (
        this.isSessionItemChosen &&
        Number.isFinite(this.project.session_item.session_item_id?.price_excl_tax)
      ) {
        source_budget = Number(this.project.session_item.session_item_id?.price_excl_tax);
      }
      if (this.project.users.users_id?.length) {
        const training = this.project.training.training_id;
        const isIndividualSession =
          training?.individual_session_item && this.getSessionItemType === SessionItemTypeEnum.INDIVIDUAL;
        if (this.project.project_type === ProjectTypeEnum.REGULATION && isIndividualSession && source_budget) {
          source_budget = source_budget * (this.project.users.users_id.length || 1);
          this.project.financing.price_excl_tax = source_budget;
          this.project.financing.budget_excl_tax = source_budget;
        }
        if (!this.show_edit) {
          this.project.financing.users_buckets = null;

          this.project.financing.users_buckets = this.project.users.users_id?.map((u) => {
            return {
              user_id: u.user_id,
              request_id: u?.request_id,
              display: u.firstname && u.lastname ? `${u.firstname} ${u.lastname}` : (u.email ?? ""),
              budget: Number(source_budget),
              cpf: 0,
              opco: 0,
              total: Number.isFinite(source_budget) ? Number(source_budget) : 0,
            };
          });
        }
      } else if (!this.show_edit && !this.project.users.users_id?.length) {
        this.project.financing.users_buckets = [];
      }
    },
    setOnDemandDates() {
      const training = this.project.training.training_id;
      if (this.isTrainingOnDemand) {
        this.project.session.create_session_step = true;

        const end_date = training?.duration_days
          ? new Date(new Date().setDate(new Date().getDate() + training.duration_days))
          : new Date();

        this.project.session.session_draft.start_date = startOfDay(new Date());
        this.project.session.session_draft.end_date = startOfDay(end_date);
      }
    },
    async updatedTrainingAndSessionItem() {
      const trainingsStore = useTrainingsStore();
      const sessionItemStore = useSessionItemStore();
      const sessionDetailStore = useSessionDetailsStore();

      const { session_item, training, session } = this.project;

      if (training?.training_id && training.training_id?.categories) {
        await trainingsStore.createUpdateTraining({
          training_id: training.training_id.training_id,
          duration_hours: training.training_id?.duration_hours,
          categories: [training.training_id?.categories[0].category_id],
          is_not_billable:
            session.session_id && training.training_id?.type !== TrainingTypeEnum.INTERNAL
              ? session.session_id.is_not_billable
              : training.training_id?.is_not_billable,
        });

        if (session_item.session_item_id && session_item.session_item_id?.session_item_id) {
          session_item.session_item_id = await sessionItemStore.updateSessionItem(training, session_item);
          if (session.session_id?.session_id && session_item?.session_item_id)
            await sessionDetailStore.updateSession(session.session_id.session_id, {
              sessionItemId: session_item.session_item_id.session_item_id,
            });
          if (this.project) this.project.session_item.session_item_id = session_item.session_item_id;
        }
      }
    },
    async confirmProject() {
      const feedback = useFeedback();
      const projectValidation = useProjectsValidation();
      const trainingsStore = useTrainingsStore();
      const sessionStore = useSessionsStore();
      const bookingStore = useBookingsStore();
      const catalogStore = useCatalogStore();

      const { session, users, training, session_item, financing } = this.project;
      let sessionId = null;
      const v$ = await projectValidation.validateConfirmProject();

      if (v$.value.$error) {
        feedback.error(`erreur lors de la confirmation du project`, "small");
        return;
      }

      const visible = this.project.training.training_id?.visible;

      if (this.isTrainingStatusDraft) {
        await this.updatedTrainingAndSessionItem();
      } else if (!session.session_id || training.training_id?.type === TrainingTypeEnum.INTERNAL) {
        await trainingsStore.createUpdateTraining({
          training_id: training.training_id?.training_id,
          is_not_billable: training.training_id?.is_not_billable,
        });
      }

      await catalogStore.changeVisibility([this.project.training.training_id?.training_id as string], visible!, false);

      if (this.isSessionDraftChosen) {
        await sessionStore.updateSessionDraft(session.session_draft_id!);
      }

      const createProjectCoreStore = useCreateProjectCoreStore();
      const start_date = session.session_id ? session.session_id.start_date : session.session_draft.start_date;
      const end_date = session.session_id ? session.session_id.end_date : session.session_draft.end_date;
      const users_ids = users.users_id || [];
      const listOfUsersId = users_ids.map(({ user_id }) => user_id);
      const users_count = listOfUsersId.length || 1;
      const isIndividualSession = this.getSessionItemType === SessionItemTypeEnum.INDIVIDUAL;
      const opco_amount = isIndividualSession
        ? (financing.opco_excl_tax ??= 0) / users_count
        : (financing.opco_excl_tax ??= 0);
      const budget_amount = isIndividualSession
        ? (financing.budget_excl_tax ??= 0) / users_count
        : (financing.budget_excl_tax ??= 0);
      const cpf_amount = isIndividualSession
        ? (financing.cpf_excl_tax ??= 0) / users_count
        : (financing.cpf_excl_tax ??= 0);
      const confirmed_at = new Date();

      if (
        listOfUsersId &&
        start_date &&
        end_date &&
        training.training_id?.company_id &&
        session_item.session_item_id?.session_item_id
      ) {
        const data = await bookingStore.regulateBooking({
          userIds: listOfUsersId,
          startDate: start_date,
          endDate: end_date,
          companyId: training.training_id?.company_id,
          sessionItemId: session_item.session_item_id?.session_item_id,
          confirmedAt: confirmed_at,
          opcoExclTax: opco_amount,
          budgetExclTax: budget_amount,
          cpfExclTax: cpf_amount,
          completionRate: 1,
        });
        sessionId = data?.sessionId || null;
      }

      if (sessionId) {
        feedback.greeting(`On est tout bon !`, "small");
        createProjectCoreStore.redirect_session_id = sessionId;
        if (BookingStatusEnum.BOOKING_CONFIRMED === this.project.notification.notification_type) {
          createProjectCoreStore.show_confirm = false;
          createProjectCoreStore.show_modal = false;
          createProjectCoreStore.show_end_modal = true;
        } else {
          this.redirectProject(sessionId, ItemType.SESSION);
        }
      } else {
        feedback.error(`erreur lors de la confirmation du project`, "small");
      }
    },

    async redirectProject(id: string, type: ItemType) {
      const $router = useRouter();

      const routeName: string = $router.currentRoute.value.name as string;

      const sessionDetailsStore = useSessionDetailsStore();
      const usePaginate = usePagination();
      await usePaginate.fetchData();
      this.$reset();

      if (routeName.includes("training-plan") || routeName.includes("training-plan")) {
        await sessionDetailsStore.showMySession(id, type);
      } else {
        return navigateTo({
          path: `/training-plan`,
          query: {
            id,
            type,
          },
        });
      }
    },
  },
});
