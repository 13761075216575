import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import luzmo_client_config_AlGxfm1aiK from "/app/plugins/luzmo.client.config.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/app/plugins/Vue3Lottie.client.ts";
import amplitude_plugin_client_zYPC55oxEH from "/app/plugins/amplitude-plugin.client.ts";
import auth_plugin_cco9pff4vd from "/app/plugins/auth-plugin.ts";
import date_picker_cVMkKXOe4l from "/app/plugins/date-picker.ts";
import helpscout_client_EOdKpmWm8J from "/app/plugins/helpscout.client.ts";
import localize_client_QxlArF5CO9 from "/app/plugins/localize.client.ts";
import sentry_client_config_o5jjUx2pcO from "/app/plugins/sentry.client.config.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  luzmo_client_config_AlGxfm1aiK,
  Vue3Lottie_client_bMZiOS6AB0,
  amplitude_plugin_client_zYPC55oxEH,
  auth_plugin_cco9pff4vd,
  date_picker_cVMkKXOe4l,
  helpscout_client_EOdKpmWm8J,
  localize_client_QxlArF5CO9,
  sentry_client_config_o5jjUx2pcO
]