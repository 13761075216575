import { defineStore } from "pinia";
import type { ErrorObject } from "@vuelidate/core";
import { isSameMinute, startOfDay } from "date-fns";
import { TrainingsLmsApi, type TrainingLmsContentDetails } from "@simbelapp/trainings-sdk";
import { useCategoriesStore } from "../categories/categories.store";
import { useSessionItemStore } from "../session-item/session-item-store";
import { useSessionsStore } from "../sessions/sessions-store";

import { useTrainingPartnersStore } from "../training-partners/training-partners.store";
import { useUsersStore } from "../users/users.store";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { usePagination } from "~/composables/paginations/usePagination";
import { useActivitiesStore } from "~/store/activities/activities.store";
import { useAuthStore } from "~/store/auth/auth.store";
import { useSkillsStore } from "~/store/skills/categories.store";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import type { UserLanguageEnum } from "~/utils/enums/users.enums";
import { useTrainingBuilderValidation } from "~~/composables/trainings/useTrainings";
import {
  SessionItemFormatEnum,
  SessionItemModalityEnum,
  SessionItemTypeEnum,
} from "~~/utils/enums/session-items.enums";
import { BuilderTrainingNavEnum, TypeBuilderPanelEnum } from "~~/utils/enums/training.enums";
import type { ICategory } from "~~/utils/interfaces/categories-interfaces";
import type {
  ICreateBuilderTraining,
  ISessionItem,
  ITrainingPartner,
  ITrainingSession,
} from "~~/utils/interfaces/trainings-interface";
import type { ICompanyUsers } from "~~/utils/interfaces/users-interfaces";
import { useActivitiesValidation } from "~/composables/activities/useActivities";

export const useCreateBuilderTrainingStore = defineStore("createBuilderTrainingStore", {
  state: (): ICreateBuilderTraining => {
    const authStore = useAuthStore();

    return {
      training: {
        content: {
          trainingId: null,
          title: null,
          trainingPartner: null,
          program: null,
          targetAudience: null,
          goals: [],
          language: (authStore.user?.language as UserLanguageEnum) ?? "fr",
          trainingAdmins: [],
        },
        session_items: {
          individual: {
            format: null,
            modality: null,
            price_excl_tax: null,
            type: null,
            max_nb_attendees: null,
            min_nb_attendees: null,
          },
        },
        modalities: {
          modality: null,
          durationDays: null,
          noRequest: null,
        },
        advanced: {
          categories: null,
          skills: null,
          visible: false,
          trainingTags: [],
        },
        sessions: {
          session_draft: {
            trainingAddress: null,
            sessionItemType: null,
            format: null,
            startDate: null,
            endDate: null,
            designation: null,
          },
          sessionList: [],
          show_add_session: false,
        },
        errors: {},
      },
      nav: BuilderTrainingNavEnum.CONTENT,
      show_modal: false,
      show_exit: false,
      show_exit_edit: false,
      is_edit: false,
      readonly: false,
      currentTab: TypeBuilderPanelEnum.TRAINING,
      showPanel: false,
      isLoading: false,
    };
  },
  getters: {},
  actions: {
    async openTrainingModal(id?: string) {
      this.$reset();
      const router = useRouter();

      if (id) {
        const activitiesStore = useActivitiesStore();
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
        await apiInstance.validateTraining({ trainingId: id });
        await router.push({ query: { id } });
        await this.loadTraining(id);
        await activitiesStore.getActivitiesList(id);
        this.showPanel = true;
        this.is_edit = true;
      }

      this.show_modal = true;
    },
    resetSessionItem(type: SessionItemTypeEnum) {
      return {
        isChecked: false,
        format: null,
        modality: SessionItemModalityEnum.SPECIFIC_DATES,
        price_excl_tax: 0,
        max_nb_attendees: 1,
        min_nb_attendees: 1,
        type,
      };
    },
    createTrainingAdmins(u: ICompanyUsers) {
      const displayValue =
        u.firstname && u.lastname ? `${u.firstname} ${u.lastname}` : u.email ? `${u.email}` : (u.employee_number ?? "");
      const defaultUser: ICompanyUsers = {
        user_id: u.user_id!,
        email: u.email ?? "",
        firstname: u.firstname ?? "",
        lastname: u.lastname ?? "",
        employee_number: u.employee_number ?? "",
        display_value: displayValue,
      };

      this.training.content.trainingAdmins.push(defaultUser);
    },
    async loadTraining(id: string) {
      this.training.sessions = {
        session_draft: null,
        sessionList: [],
        show_add_session: false,
      };

      await this.loadTrainingContent({ trainingId: id });
      await this.loadSessions(id);

      this.training.sessions.sessionList = this.training.sessions.sessionList ?? [];
      this.training.sessions.session_draft = {
        trainingAddress: null,
        sessionItemType: null,
        format: null,
        startDate: null,
        endDate: null,
        designation: null,
      };
    },

    async loadSessions(trainingId: string) {
      const sessionsStore = useSessionsStore();

      this.training.sessions.sessionList = (await sessionsStore.fetchSessionsByTrainingId(
        trainingId,
        "false",
      )) as ITrainingSession[];
    },

    pushValidationErrors(errors: ErrorObject[]) {
      errors.forEach((err: ErrorObject) => {
        this.training.errors[err.$propertyPath] = err.$message as string;
      });
    },

    selectNewTrainingPartner(partner_name: string) {
      this.training.content.trainingPartner = {
        training_partner_id: null,
        name: partner_name,
      };
    },

    selectNewCategory(category_name: string) {
      const categoryStore = useCategoriesStore();
      const alreadyExist =
        categoryStore.categories &&
        categoryStore.categories.find((cat) => {
          return cat.name === category_name;
        });
      if (!alreadyExist) {
        const newCategories: ICategory = {
          name: category_name,
          code_name: "🎓",
          is_company_category: true,
          priority: 12,
        };

        this.training.advanced.categories = newCategories;
        categoryStore.categories && categoryStore.categories.push(newCategories);
      }
    },

    async createSessionItems(
      sessionItems: {
        individual: ISessionItem;
      },
      trainingId: string,
    ) {
      const sessionItemStore = useSessionItemStore();

      return await sessionItemStore.createSessionItem(
        {
          ...sessionItems.individual,
        },
        trainingId,
      );
    },

    async createTrainingPartner() {},
    async createUpdateCategories() {
      const categoriesStore = useCategoriesStore();
      const catFilters = categoriesStore.categories?.filter((cat) => cat.is_company_category) as ICategory[];
      await categoriesStore.createUpdateCategories(catFilters);
    },
    async updateCategoriesIdSelected() {
      const categoriesStore = useCategoriesStore();
      await categoriesStore.fetchCategories();
      if (categoriesStore.categories) {
        this.training.advanced.categories = categoriesStore.categories?.find(
          (cat) => this.training.advanced.categories && cat.name === this.training.advanced.categories.name,
        ) as ICategory;
      }
    },
    async createHabilitation() {},
    async createUpdateTraining() {},
    async updateContactEmail() {},
    async addTraining() {
      const trainingValidation = useTrainingBuilderValidation();
      const feedback = useFeedback();
      const { content } = this.training;
      this.training.errors = {};
      await trainingValidation.validateStepAdvanced();

      if (!Object.keys(this.training.errors).length) {
        try {
          if (content.trainingId) {
            const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);

            await apiInstance.validateTraining({ trainingId: content.trainingId });

            this.is_edit = true;
            this.nav = BuilderTrainingNavEnum.SESSIONS;

            feedback.success("La formation a bien été créée", "small");

            const router = useRouter();
            await router.push({
              query: { id: this.training.content.trainingId },
            });
            await this.loadTraining(content.trainingId);

            const paginate = usePagination();
            await paginate.fetchData();
          }
        } catch (error) {
          feedback.error(`Une erreur est survenue`, "small");
        }
      }
    },
    async editTraining() {},

    async fetchSessionItems() {
      const { content, session_items } = this.training;
      if (content.trainingId) {
        const sessionItemStore = useSessionItemStore();
        const sessionItems = await sessionItemStore.findAllSessionItems(content.trainingId);
        session_items.individual.designation = sessionItems.designation;
        session_items.individual.min_nb_attendees = sessionItems.min_nb_attendees;
        session_items.individual.max_nb_attendees = sessionItems.max_nb_attendees;
        session_items.individual.price_excl_tax = sessionItems.price_excl_tax;
        session_items.individual.format = sessionItems.format;
        session_items.individual.modality = sessionItems.modality;
        session_items.individual.type = sessionItems.type;
        session_items.individual.session_item_id = sessionItems.session_item_id;
      }
    },

    async addSession() {
      const feedback = useFeedback();
      if (this.nav === BuilderTrainingNavEnum.SESSIONS) {
        this.training.errors = {};
        await this.validation();

        if (
          !Object.keys(this.training.errors).length &&
          this.training.sessions.session_draft &&
          this.training.content.trainingId
        ) {
          this.training.sessions.session_draft.sessionItemId = this.training.session_items.individual
            .session_item_id as string;

          if (!this.training.sessions.session_draft.trainingAddress) {
            this.training.sessions.session_draft.trainingAddress = this.training.session_items.individual
              .location as string;
          }

          this.training.sessions.show_add_session = false;

          const sessionStore = useSessionsStore();

          await sessionStore.createSession(this.training.sessions.session_draft);

          this.training.sessions.sessionList = (await sessionStore.fetchSessionsByTrainingId(
            this.training.content.trainingId,
            "false",
          )) as ITrainingSession[];

          if (this.training.sessions.sessionList.length) {
            this.training.sessions.session_draft = {
              trainingAddress: null,
              sessionItemType: SessionItemTypeEnum.INDIVIDUAL,
              format: SessionItemFormatEnum.ONLINE,
              startDate: null,
              endDate: null,
              designation: null,
            };

            this.training.errors = {};
            feedback.success("La session a bien été ajoutée", "small");
          }
        }
      }
    },
    async validation() {
      const trainingValidation = useTrainingBuilderValidation();
      const activitiesValidation = useActivitiesValidation();
      this.training.errors = {};
      if (this.nav === BuilderTrainingNavEnum.CONTENT) {
        await trainingValidation.validateStepContentFull();
        await activitiesValidation.validateActivity();
        await activitiesValidation.validateEmptyActivityQuiz();
      }
      if (this.nav === BuilderTrainingNavEnum.MODALITIES) await trainingValidation.validateStepModality();
      if (this.nav === BuilderTrainingNavEnum.ADVANCED) await trainingValidation.validateStepAdvanced();

      if (this.nav === BuilderTrainingNavEnum.SESSIONS) {
        await trainingValidation.validateStepSession();
        if (this.training.sessions.session_draft) {
          const start =
            this.training.sessions.session_draft.startDate &&
            new Date(this.training.sessions.session_draft.startDate as string);
          const end =
            this.training.sessions.session_draft.endDate &&
            new Date(this.training.sessions.session_draft.endDate as string);

          this.training.errors =
            start &&
            end &&
            (start > end ||
              (isSameMinute(start, startOfDay(start)) && !isSameMinute(end, startOfDay(end))) ||
              (!isSameMinute(start, startOfDay(start)) && isSameMinute(end, startOfDay(end))))
              ? {
                  ...this.training.errors,
                  dates: "error between endDate and startDate",
                }
              : this.training.errors;
        }
      }
    },
    async assignTrainingAdmins(trainingAdminsIds: string[]) {
      const usersStore = useUsersStore();

      this.training.content.trainingAdmins = [];

      if (trainingAdminsIds?.length) {
        await usersStore.fetchCompanyUsersLazyWithStore(false, undefined, trainingAdminsIds);

        usersStore.company_users?.forEach((user) => {
          if (trainingAdminsIds?.includes(user.user_id)) this.createTrainingAdmins(user);
        });
      } else {
        await usersStore.fetchCompanyUsersLazyWithStore(false);
      }
    },
    async assignPartner(trainingPartnerId: string | null) {
      const trainingPartnersStore = useTrainingPartnersStore();

      this.training.content.trainingPartner = null;

      if (trainingPartnerId) {
        await trainingPartnersStore.fetchTrainingPartnersLazyWithStore(trainingPartnerId);

        this.training.content.trainingPartner =
          (trainingPartnersStore.training_partners as ITrainingPartner[])?.find(
            (partner) => partner.training_partner_id === trainingPartnerId,
          ) ?? null;
      } else {
        await trainingPartnersStore.fetchTrainingPartnersLazyWithStore();
      }
    },

    async assignCategory(categoryId: string | null) {
      const categoriesStore = useCategoriesStore();
      if (!categoriesStore?.categories?.length) {
        await categoriesStore.fetchCategories();
      }

      this.training.advanced.categories = null;

      if (categoryId) {
        this.training.advanced.categories =
          categoriesStore.categories?.find((category) => category.category_id === categoryId) ?? null;
      }
    },

    async assignSkill(skillIds: string[] | null) {
      const skillsStore = useSkillsStore();
      if (!skillsStore?.skills?.length) {
        await skillsStore.fetchSkills();
      }

      this.training.advanced.skills = null;

      if (skillIds?.length) {
        this.training.advanced.skills =
          skillsStore.skills?.filter((skill) => skillIds.includes(skill.skill_id)) ?? null;
      }
    },

    async loadTrainingContent(parameters: { trainingId?: string; trainingContent?: TrainingLmsContentDetails }) {
      const { trainingId } = parameters;
      let { trainingContent } = parameters;

      if (!trainingContent && trainingId) {
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
        trainingContent = await apiInstance.getLmsTrainingContent({ trainingId });
      }

      if (trainingContent) {
        const { content } = this.training;
        content.title = trainingContent.title || null;
        content.trainingId = trainingContent.trainingId;
        content.goals = trainingContent.goals || [];
        content.language = trainingContent.language as UserLanguageEnum;
        content.targetAudience = trainingContent.targetAudience || null;
        content.program = trainingContent.program || null;
        await this.assignTrainingAdmins(trainingContent.trainingAdminsIds || []);
        await this.assignPartner(trainingContent.trainingPartnerId || null);
      }
    },

    async loadTrainingModalities(trainingId: string) {
      if (trainingId) {
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
        const trainingModalities = await apiInstance.getLmsTrainingModalities({ trainingId });

        if (trainingModalities) {
          const { modalities } = this.training;
          modalities.modality = (trainingModalities.modality as SessionItemModalityEnum) || null;
          modalities.durationDays = trainingModalities.durationDays || null;
          modalities.noRequest = trainingModalities.noRequest as boolean;
        }
      }
    },

    async loadAdvancedInfos(trainingId: string) {
      if (trainingId) {
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
        const trainingAdvancedInfos = await apiInstance.getTrainingLmsAdvancedInfos({ trainingId });

        if (trainingAdvancedInfos) {
          const { advanced } = this.training;

          await Promise.all([this.assignCategory(trainingAdvancedInfos.categoryId || null)]);
          advanced.trainingTags = trainingAdvancedInfos.trainingTags || [];
          advanced.visible = trainingAdvancedInfos.visible || false;
        }
      }
    },

    async createTraining() {
      const trainingValidation = useTrainingBuilderValidation();
      this.training.errors = {};
      await trainingValidation.validateStepContent();
      const { content } = this.training;
      const feedback = useFeedback();

      if (!this.training.errors.length) {
        try {
          if (content.title) {
            const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
            const training = await apiInstance.createLmsTraining({
              createLMSTrainingDto: {
                title: content.title,
                trainingPartnerId: content.trainingPartner?.training_partner_id ?? null,
                trainingPartnerName:
                  content.trainingPartner?.name && !content.trainingPartner?.training_partner_id
                    ? content.trainingPartner?.name
                    : null,
              },
            });

            if (training) {
              await this.loadTrainingContent(training);
              feedback.success("Vos modifications ont bien été sauvegardées", "small");
            }
          }
        } catch (error) {
          feedback.error(`Une erreur est survenue`, "small");
        }
      }
    },
    async updateTrainingStepContent(element: string) {
      const feedback = useFeedback();
      try {
        const { content } = this.training;

        let el = null;
        if (Object.prototype.hasOwnProperty.call(content, element)) {
          if (element === "trainingAdmins") {
            el = {
              trainingAdminsIds: content.trainingAdmins?.map((admin) => admin.user_id) || [],
            };
          } else if (element === "trainingPartner") {
            el = {
              trainingPartnerId: content.trainingPartner?.training_partner_id ?? null,
              trainingPartnerName:
                content.trainingPartner?.name && !content.trainingPartner?.training_partner_id
                  ? content.trainingPartner?.name
                  : null,
            };
          } else {
            el = {
              [element]: content[element as keyof typeof content],
            };
          }
        }

        if (content.trainingId) {
          const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
          await apiInstance.updateLmsTrainingContent({
            trainingId: content.trainingId,
            updateLMSTrainingContentDto: {
              ...el,
            },
          });
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    async updateTrainingStepModality(element: string) {
      const feedback = useFeedback();
      try {
        const { modalities, content } = this.training;

        let el = null;
        if (Object.prototype.hasOwnProperty.call(modalities, element)) {
          el = {
            [element]: modalities[element as keyof typeof modalities],
          };
        }

        if (content.trainingId) {
          const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
          await apiInstance.updateLmsTrainingModalities({
            trainingId: content.trainingId,
            updateLMSTrainingModalitiesDto: {
              ...el,
            },
          });
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async updateTrainingStepAdvanced(element: string) {
      const feedback = useFeedback();
      try {
        const { content, advanced } = this.training;

        let el = null;
        if (Object.prototype.hasOwnProperty.call(advanced, element)) {
          if (element === "categories") {
            await this.createUpdateCategories();
            await this.updateCategoriesIdSelected();

            el = {
              categoryId: advanced.categories?.category_id || null,
            };
          } else {
            el = {
              [element]: advanced[element as keyof typeof advanced],
            };
          }
        }

        if (content.trainingId) {
          const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
          await apiInstance.updateLMSTrainingAdvancedInfosDto({
            trainingId: content.trainingId,
            updateLMSTrainingAdvancedInfosDto: {
              ...el,
            },
          });
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
    openAddSession() {
      this.training.sessions.show_add_session = !this.training.sessions.show_add_session;

      this.training.sessions.session_draft = {
        trainingAddress: null,
        sessionItemType: SessionItemTypeEnum.INDIVIDUAL,
        format: SessionItemFormatEnum.ONLINE,
        startDate: null,
        endDate: null,
        designation: null,
      };

      this.training.errors = {};
    },

    async removeTraining(trainingId: string) {
      const feedback = useFeedback();
      try {
        const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingsLmsApi);
        await apiInstance.removeTrainingLms({ trainingId });
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },
  },
});
